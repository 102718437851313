<template>
	<div style="text-align: center;">
		<div style="display:flex">
			<img src="../../assets/goods/01.jpg" style="width: 100%;"/>
		</div>
		<div style="display:flex">
			<img src="../../assets/goods/02.jpg" style="width: 100%;"/>
		</div>
		<div style="display:flex">
			<img src="../../assets/goods/03.jpg" style="width: 100%;"/>
		</div>
		<div style="display:flex">
			<img src="../../assets/goods/04.jpg" style="width: 100%;"/>
		</div>
		<div style="display:flex">
			<img src="../../assets/goods/07.jpg" style="width: 100%;"/>
		</div>
	</div>
		
</template>

<script>
import { init } from '@/common/weixinSDK'
export default {
	name: 'index',
	components: {
	},
	data() {
		return {
			title:"平台简介",
			imgUrl: 'https://static.tannatong.com/sanlinghui/common/goods_tian.jpg'
		}
	},
	created() {
		init({
			APIs: ['hideOptionMenu', 
			'hideAllNonBaseMenuItem', 
			'showMenuItems', 
			'hideMenuItems', 
			'onMenuShareTimeline', 
			'onMenuShareAppMessage'],
			callback: {
				'title': '叁個零天款酱香白酒',
				'imgUrl': this.imgUrl,
				'description': '自然茅香、极品老酒，对标15年飞天，专用于高端接待/高档礼品/高净值人士口粮酒。',
				'url': "http://www.sangeling.com/goods/tian",
				successMethod: ''
			}
		})
	},
	methods: {
		
	}
}
</script>

<style>
body{
	margin: 0;
}
.title{
	background-color: #fad182; 
	border-radius: 30px; 
	display: inline-block;
	padding: 5px 20px;
	font-weight: bold;
}
.title2{
	color: #fff;
	font-weight: bold;
	display: inline-block;
	background-color: #000000; 
	border-radius: 4px;
}
.title2 .left{
	display: inline-block;
	padding: 5px 10px; 
}
.title2 .right{
	display: inline-block; 
	background-color: #e6141d;
	padding: 5px 10px; 
	border-radius: 0 4px 4px 0;
}
</style>
